<template>
   <div id="main-wrapper">
      <!-- <Container/> -->
      <section class="blockElement">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-12 col-md-9 text-center">
                  <h1 class="mb-0">{{contentlist.merchant_solution_content1}} <span class="typed-text primarycolor"></span><span class="cursor">&nbsp;</span>{{contentlist.merchant_solution_content2}}</h1>
                  <p class="d-block mt-4 mb-0">{{contentlist.merchant_solution_content3}}</p>
               </div>
            </div>
            <div class="row justify-content-between align-items-center mt-5 merchantPaymentBox">
               <div class="col-12 col-md-5 col-lg-6">
                  <img src="/assets/images/merchant-payment.webp" alt="merchant payment" class="img-fluid" /> 
               </div>
               <div class="col-12 col-md-7 col-lg-5 mt-4 mt-md-0">
                  <ul class="listIcon">
                     <li>{{contentlist.merchant_solution_content4}}</li>
                     <li>{{contentlist.merchant_solution_content5}}</li>


                     <!-- Global Start -->
                     <li v-if="entityName == 'global'">{{contentlist.merchant_solution_global_content6}}</li>
                     <li v-if="entityName == 'global'">{{contentlist.merchant_solution_global_content7}}</li>
                     <!-- Global End -->

                     <!-- APEC Start -->
                     <li v-if="entityName == 'apec'">{{contentlist.merchant_solution_apec_content6}}</li>
                     <li v-if="entityName == 'apec'">{{contentlist.merchant_solution_apec_content7}}</li>
                     <!-- APEC End -->
                  
                  </ul>
                  <div class="d-block text-left mt-0 pt-0 mt-lg-5 pt-lg-4"><a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="themeBtn medium">{{contentlist.start_now_content}}</a></div>
               </div>
            </div>
         </div>
      </section>
      <section class="blockElement lightgreybg pb-3">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-12 col-md-9 text-center">
                  <h2 class="mb-0">{{contentlist.merchant_solution_content8}}</h2>
                  <p class="d-block mt-4 mb-0">{{contentlist.merchant_solution_content9}}</p>
               </div>
            </div>
         </div>
      </section>
      <section class="blockElement lightgreybg pt-4 pb-5">
         <Carousel class="merchantGatewayCarousel" :wrap-around="true" :settings="settings" :autoplay="2000">
            <Slide key="1">
               <div class="carousel__item">
                  <div class="merchantGatewayBox text-center bg-white round-md">
                     <img src="/assets/images/merchant-real-estate.webp" alt="Real-Estate" class="img-fluid" /> 
                     <h6 class="mb-0">{{contentlist.merchant_solution_content10}}</h6>
                  </div>
               </div>
            </Slide>
            <Slide key="2" v-show="entityName == 'global'">
               <div class="carousel__item">
                  <div class="merchantGatewayBox text-center bg-white round-md">
                     <img src="/assets/images/merchant-financial-services.webp" alt="Financial Services" class="img-fluid" /> 
                     <h6 class="mb-0">{{contentlist.merchant_solution_content11}}</h6>
                  </div>
               </div>
            </Slide>
            <Slide key="3">
               <div class="carousel__item">
                  <div class="merchantGatewayBox text-center bg-white round-md">
                     <img src="/assets/images/merchant-ecommerce.webp" alt="E-Commerce" class="img-fluid" /> 
                     <h6 class="mb-0">{{contentlist.merchant_solution_content12}}</h6>
                  </div>
               </div>
            </Slide>
            <Slide key="4">
               <div class="carousel__item">
                  <div class="merchantGatewayBox text-center bg-white round-md">
                     <img src="/assets/images/merchant-marketplaces.webp" alt="Marketplaces" class="img-fluid" /> 
                     <h6 class="mb-0">{{contentlist.merchant_solution_content13}}</h6>
                  </div>
               </div>
            </Slide>
            <Slide key="5" v-show="entityName == 'global'">
               <div class="carousel__item">
                  <div class="merchantGatewayBox text-center bg-white round-md">
                     <img src="/assets/images/merchant-gaming.webp" alt="Gaming" class="img-fluid" /> 
                     <h6 class="mb-0">{{contentlist.merchant_solution_content14}}</h6>
                  </div>
               </div>
            </Slide>
            <Slide key="6">
               <div class="carousel__item">
                  <div class="merchantGatewayBox text-center bg-white round-md">
                     <img src="/assets/images/merchant-telecoms.webp" alt="Telecoms" class="img-fluid" /> 
                     <h6 class="mb-0">{{contentlist.merchant_solution_content15}}</h6>
                  </div>
               </div>
            </Slide>
            <Slide key="7">
               <div class="carousel__item">
                  <div class="merchantGatewayBox text-center bg-white round-md">
                     <img src="/assets/images/merchant-hospitality.webp" alt="Hospitality" class="img-fluid" /> 
                     <h6 class="mb-0">{{contentlist.merchant_solution_content16}}</h6>
                  </div>
               </div>
            </Slide>
            <Slide key="8">
               <div class="carousel__item">
                  <div class="merchantGatewayBox text-center bg-white round-md">
                     <img src="/assets/images/merchant-saas-providers.webp" alt="SaaS Providers" class="img-fluid" /> 
                     <h6 class="mb-0">{{contentlist.merchant_solution_content17}}</h6>
                  </div>
               </div>
            </Slide>
            <Slide key="9">
               <div class="carousel__item">
                  <div class="merchantGatewayBox text-center bg-white round-md">
                     <img src="/assets/images/merchant-consulting.webp" alt="Consulting" class="img-fluid" /> 
                     <h6 class="mb-0">{{contentlist.merchant_solution_content18}}</h6>
                  </div>
               </div>
            </Slide>
            <Slide key="10">
               <div class="carousel__item">
                  <div class="merchantGatewayBox text-center bg-white round-md">
                     <img src="/assets/images/merchant-marketing-agencies.webp" alt="Marketing Agencies" class="img-fluid" /> 
                     <h6 class="mb-0">{{contentlist.merchant_solution_content19}}</h6>
                  </div>
               </div>
            </Slide>
            <Slide key="11">
               <div class="carousel__item">
                  <div class="merchantGatewayBox text-center bg-white round-md">
                     <img src="/assets/images/merchant-translators.webp" alt="Translators" class="img-fluid" /> 
                     <h6 class="mb-0">{{contentlist.merchant_solution_content20}}</h6>
                  </div>
               </div>
            </Slide>
         </Carousel>
      </section>
      <section class="blockElement">
         <div class="container">
            <h2 class="text-center mb-0">{{contentlist.merchant_solution_content21}}</h2>
            <div class="row merchantCapitalMainBox">
               <div class="col-3">
                  <div class="merchantCapitalBox text-center text-md-start mt-4">
                     <div class="greyBgIcon rounded-pill d-flex align-items-center justify-content-center lightgreybg m-auto m-md-0">
                        <img src="/assets/images/capital-ultra-pricing.webp" alt="Ultra Low Pricing" class="img-fluid">
                     </div>
                     <h5 class="m-0 lh-base pt-4">{{contentlist.merchant_solution_content22}}</h5>
                     <p class="m-0 lh-base pt-3">{{contentlist.merchant_solution_content23}}</p>
                  </div>
               </div>
               <div class="col-3">
                  <div class="merchantCapitalBox text-center text-md-start mt-4">
                     <div class="greyBgIcon rounded-pill d-flex align-items-center justify-content-center lightgreybg m-auto m-md-0">
                        <img src="/assets/images/capital-global-payments.webp" alt="Global Payments" class="img-fluid">
                     </div>
                     <h5 class="m-0 lh-base pt-4">{{contentlist.merchant_solution_content24}}</h5>
                     <p class="m-0 lh-base pt-3">{{contentlist.merchant_solution_content25}}</p>
                  </div>
               </div>
               <div class="col-3">
                  <div class="merchantCapitalBox text-center text-md-start mt-4">
                     <div class="greyBgIcon rounded-pill d-flex align-items-center justify-content-center lightgreybg m-auto m-md-0">
                        <img src="/assets/images/capital-auto-settlement.webp" alt="Auto Settlement" class="img-fluid">
                     </div>
                     <h5 class="m-0 lh-base pt-4">{{contentlist.merchant_solution_content26}}</h5>

                     <!-- Global Start -->
                     <p class="m-0 lh-base pt-3" v-if="entityName == 'global'">{{contentlist.merchant_solution_global_content27}}</p>
                     <!-- Global End -->

                     <!-- APEC Start -->
                     <p class="m-0 lh-base pt-3" v-if="entityName == 'apec'">{{contentlist.merchant_solution_apec_content27}}</p>
                     <!-- APEC End -->
                  
                  </div>
               </div>
               <div class="col-3">
                  <div class="merchantCapitalBox text-center text-md-start mt-4">
                     <div class="greyBgIcon rounded-pill d-flex align-items-center justify-content-center lightgreybg m-auto m-md-0">
                        <img src="/assets/images/capital-cold-storage.webp" alt="Cold Storage" class="img-fluid">
                     </div>
                     <h5 class="m-0 lh-base pt-4">{{contentlist.merchant_solution_content28}}</h5>
                     <p class="m-0 lh-base pt-3">{{contentlist.merchant_solution_content29}}</p>
                  </div>
               </div>
               <div class="col-3">
                  <div class="merchantCapitalBox text-center text-md-start mt-4">
                     <div class="greyBgIcon rounded-pill d-flex align-items-center justify-content-center lightgreybg m-auto m-md-0">
                        <img src="/assets/images/capital-unmatched-security.webp" alt="Unmatched Security" class="img-fluid">
                     </div>
                     <h5 class="m-0 lh-base pt-4">{{contentlist.merchant_solution_content30}}</h5>
                     <p class="m-0 lh-base pt-3">{{contentlist.merchant_solution_content31}}</p>
                  </div>
               </div>
               <div class="col-3">
                  <div class="merchantCapitalBox text-center text-md-start mt-4">
                     <div class="greyBgIcon rounded-pill d-flex align-items-center justify-content-center lightgreybg m-auto m-md-0">
                        <img src="/assets/images/capital-instant-conversion.webp" alt="Unmatched Security" class="img-fluid">
                     </div>
                     <h5 class="m-0 lh-base pt-4">{{contentlist.merchant_solution_content32}}</h5>

                     <!-- Global Start -->
                     <p class="m-0 lh-base pt-3" v-if="entityName == 'global'">{{contentlist.merchant_solution_global_content33}}</p>
                     <!-- Global End -->

                     <!-- APEC Start -->
                     <p class="m-0 lh-base pt-3" v-if="entityName == 'apec'">{{contentlist.merchant_solution_apec_content33}}</p>
                     <!-- APEC End -->
                     
                  </div>
               </div>
               <div class="col-3">
                  <div class="merchantCapitalBox text-center text-md-start mt-4">
                     <div class="greyBgIcon rounded-pill d-flex align-items-center justify-content-center lightgreybg m-auto m-md-0">
                        <img src="/assets/images/capital-api-integration.webp" alt="Unmatched Security" class="img-fluid">
                     </div>
                     <h5 class="m-0 lh-base pt-4">{{contentlist.merchant_solution_content34}}</h5>
                     <p class="m-0 lh-base pt-3">{{contentlist.merchant_solution_content35}}</p>
                  </div>
               </div>
               <div class="col-3">
                  <div class="merchantCapitalBox text-center text-md-start mt-4">
                     <div class="greyBgIcon rounded-pill d-flex align-items-center justify-content-center lightgreybg m-auto m-md-0">
                        <img src="/assets/images/capital-dedicated-dashboard.webp" alt="Unmatched Security" class="img-fluid">
                     </div>
                     <h5 class="m-0 lh-base pt-4">{{contentlist.merchant_solution_content36}}</h5>
                     <p class="m-0 lh-base pt-3">{{contentlist.merchant_solution_content37}}</p>
                  </div>
               </div>
            </div>
            <div class="d-block text-center mt-5"><a :href="static_vars.merchantPortalLinks[entityName].register" class="themeBtn medium">{{contentlist.join_now_content}}</a></div>
         </div>
      </section>
      <section class="blockElement pt-0">
         <div class="container">
            <h2 class="mb-0 text-center">{{contentlist.how_it_works_content}}</h2>
            <div class="lightgreybg p-2 p-lg-3 p-xl-5 round-sm mt-5">
               <img src="/assets/images/capital-how-works-desktop.webp" alt="How It Works" class="img-fluid capitalWorkDesktop">
               <img src="/assets/images/capital-how-works-mobile.webp" alt="How It Works" class="img-fluid capitalWorkMobile">
            </div>
            <div class="d-block text-center mt-5"><a :href="static_vars.merchantPortalLinks[entityName].register" class="themeBtn medium">{{contentlist.start_now_content}}</a></div>
         </div>
      </section>
      <section class="blockElement py-0">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-12 col-md-9 text-center">
                  <h2 class="mb-0">{{contentlist.merchant_solution_content38}}</h2>
                  <p class="mb-0 pt-3">{{contentlist.merchant_solution_content39}}</p>
                  <img src="/assets/images/from-to-developers.webp" alt="To Developers, From Developers" class="img-fluid mt-5 toFromDevelopers">
                  <div class="d-block text-center mt-5"><a href="https://apidocs.capitalwallet.com/" target="_blank" class="themeBtn medium">{{contentlist.read_more_content}}</a></div>
               </div>
            </div>
         </div>
      </section>
      <section class="blockElement pt-0">
         <div class="container">
            <div class="mt-5 pt-3">
               <div class="row">
                  <div class="col-md-4">
                     <div class="bordered round-md m-auto text-center px-4 mb-4 mb-md-0">
                        <div class="flexibleContentBox">
                           <h6 class="pt-4 pt-lg-5">{{contentlist.flexible_box_content1}}</h6>
                           <p class="pt-3 pt-lg-4">{{contentlist.flexible_box_content2}} <br> {{contentlist.flexible_box_content3}}</p>
                        </div>
                        <router-link :to="entityPath+'/payout'" class="borderBtn medium mt-2 mt-lg-3 mb-4 mb-lg-5">{{contentlist.find_out_more_content}}</router-link>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="bordered round-md m-auto text-center px-4 mb-4 mb-md-0">
                        <div class="flexibleContentBox">
                           <h6 class="pt-4 pt-lg-5">{{contentlist.flexible_box_content5}}</h6>
                           <p class="pt-3 pt-lg-4">{{contentlist.flexible_box_content6}}<br> {{contentlist.flexible_box_content7}}</p>
                        </div>
                        <router-link :to="entityPath+'/crypto-conversion'" class="borderBtn medium mt-2 mt-lg-3 mb-4 mb-lg-5">{{contentlist.find_out_more_content}}</router-link>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="bordered round-md m-auto text-center px-4 mb-4 mb-md-0">
                        <div class="flexibleContentBox">
                           <h6 class="pt-4 pt-lg-5">{{contentlist.flexible_box_content8}}</h6>
                           <p class="pt-3 pt-lg-4">{{contentlist.flexible_box_content9}}</p>
                        </div>
                        <router-link :to="entityPath+'/contact-us'" class="borderBtn medium mt-2 mt-lg-3 mb-4 mb-lg-5">{{contentlist.contact_us_content}}</router-link>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
     
      <!-- <Footer /> -->
      <section class="blockElement primarybg" data-aos="fade-up" data-aos-duration="1800">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-12 text-center primarybgSection">
                  <div class="criptoIcon">
                     <span class="left-tp one"><img src="/assets/images/onHover-2.webp" alt="Icon" title="Crypto 1" width="77" height="90" class="img-fluid"></span>
                     <span class="left-tp tow"><img src="/assets/images/onHover-1.webp" alt="Icon" title="Crypto 2" width="98" height="98" class="img-fluid"></span>
                     <span class="left-tp three"><img src="/assets/images/onHover-3.webp" alt="Icon" title="Crypto 3" width="56" height="89" class="img-fluid"></span>
                     <span class="left-tp four"><img src="/assets/images/onHover-4.webp" alt="Icon" title="Crypto 4" width="87" height="86" class="img-fluid"></span>
                  </div>
                  <div class="content position-relative">
                     <h2 class="whitecolor">{{contentlist.get_started_content}}</h2>
                     <p class="whitecolor regular mb-0">{{contentlist.cta_three_content2}}</p>
                     <a :href="static_vars.merchantPortalLinks[entityName].register" class="whiteBtn medium mt-4">{{contentlist.register_now_content}}</a>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>
<script>
   import { commonAllmixins } from '@/plugins/commonAll'   //anamica
   import "vue3-carousel/dist/carousel.css";
   import { Carousel, Slide } from 'vue3-carousel'
   export default ({
      mixins: [ commonAllmixins],   //anamica
      name: "Autoplay",
      data() {
         return {
            tab: 1,
            tabConversion: 1,
            settings: {
               snapAlign: 'start',
               breakpoints: {
                  320: {
                     itemsToShow: 1,
                     itemsToScroll:2,
                     snapAlign: 'start',
                  },
                  576: {
                     itemsToShow: 2,
                     itemsToScroll: 1,
                     snapAlign: 'start',
                  },
                  768: {
                     itemsToShow: 3,
                     itemsToScroll: 1,
                     snapAlign: 'start',
                  },
                  1200: {
                     itemsToShow: 4,
                     itemsToScroll: 1,
                     snapAlign: 'start',
                  },
                  1600: {
                     itemsToShow: 5,
                     itemsToScroll: 1,
                     snapAlign: 'start',
                  },
               },
            },
         };
      },
      created(){
         console.log(this.contentlist)
      },
      components: { Carousel, Slide },
      mounted: function() {
        let arraydata = ["BTC", "ETH", "LTC", "USDT", "USDC", "DAI"];
        this.typeTextFunc(arraydata)
      }
   });
</script>